import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api/api';

export const getRNPLs = createAsyncThunk('RNPL/getPaginatedRNPL', async ({ reference_number }) => {
    try {
        let url = '';
        if (reference_number && page) {
            url = `/rnpl/getRNPL?ref_number=${reference_number}&page=${page}`;
        } else {
            url = `/rnpl/getRNPL?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getRNPLsNotPaginated = createAsyncThunk('RNPL/getRNPLs', async () => {
    try {
        const { data } = await api.get(`/rnpl/getRNPL`);
        return data;
    } catch (error) {}
});
export const getRNPL = createAsyncThunk('RNPL/getRNPL', async ({ id }) => {
    try {
        const { data } = await api.get(`/rnpl/getRNPL?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteRNPLs = createAsyncThunk('RNPL/deleteRNPL', async ({ id }) => {
    try {
        const { data } = await api.delete(`/rnpl/deleteRNPL/${id}`);
        return data;
    } catch (error) {}
});

export const addRNPLs = createAsyncThunk(
    'RNPL/addRNPL',
    async ({ term, payment_schedule, amount, due_date, repayment_amount, processing_fee, category, interest_rate, payment_dates }) => {
        try {
            const { data } = await api.post(`/rnpl/addRNPL`, {
                term,
                payment_schedule,
                amount,
                due_date,
                category,
                repayment_amount,
                processing_fee,
                interest_rate,
                payment_dates
            });
            return data;
        } catch (error) {}
    }
);

export const updateRNPLs = createAsyncThunk(
    'RNPL/updateRNPL',
    async ({ id, term, payment_schedule, amount, due_date, repayment_amount, balance, late_fee, status, paid_status }) => {
        try {
            const { data } = await api.put(`/rnpl/editRNPL/${id}`, {
                id,
                term,
                payment_schedule,
                amount,
                due_date,
                repayment_amount,
                balance,
                late_fee,
                status,
                paid_status
            });
            return data;
        } catch (error) {}
    }
);
