import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../../../api/api';

export const getApplications = createAsyncThunk('Application/getPaginatedApplication', async ({ name, unit_id, status, phone, email }) => {
    try {
        let url = '';
        if (name && page) {
            url = `/products/getApplication?fisrt_name=${name}&page=${page}`;
        } else if (email) {
            url = `/products/getApplication?email=${email}`;
        } else if (phone) {
            url = `/products/getApplication?phone=${phone}`;
        } else if (status && page) {
            url = `/products/getApplication?last_name=${status}&page=${page}`;
        } else if (unit_id && page) {
            url = `/products/getApplication?last_name=${unit_id}&page=${page}`;
        } else {
            url = `/products/getApplication?page=${page}`;
        }
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});

//not paginated

export const getApplicationsNotPaginated = createAsyncThunk('Application/getApplications', async ({ property_id }) => {
    let url = '';
    if (property_id) {
        url = `/products/getApplication?property_id=${property_id}`;
    } else {
        url = `/products/getApplication`;
    }
    try {
        const { data } = await api.get(url);
        return data;
    } catch (error) {}
});
export const getApplication = createAsyncThunk('Application/getApplication', async ({ id }) => {
    try {
        const { data } = await api.get(`/products/getApplication?id=${id}`);
        return data;
    } catch (error) {}
});

export const deleteApplications = createAsyncThunk('Application/deleteApplication', async ({ id }) => {
    try {
        const { data } = await api.delete(`/products/deleteApplication/${id}`);
        return data;
    } catch (error) {}
});

export const addApplications = createAsyncThunk('Application/addApplication', async ({ unit_id, application_data, rnpl }) => {
    try {
        const { data } = await api.post(`/products/addApplication`, {
            unit_id,
            application_data,
            rnpl
        });
        return data;
    } catch (error) {}
});

export const updateApplications = createAsyncThunk('Application/updateApplication', async ({ id, unit_id, application_data, status }) => {
    try {
        const { data } = await api.patch(`/products/editApplication/${id}`, {
            id,
            unit_id,
            application_data,
            status
        });
        return data;
    } catch (error) {}
});

export const inviteTenant = createAsyncThunk('Application/inviteTenant', async ({ first_name, last_name, phone, email, unitId }) => {
    try {
        const { data } = await api.post(`/products/inviteToApply`, {
            first_name,
            last_name,
            phone,
            email,
            unitId
        });
        return data;
    } catch (error) {}
});
