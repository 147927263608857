import { createSlice } from '@reduxjs/toolkit';
import { addUnits, deleteUnits, getUnits, getUnit, getNotPaginatedUnits, updateUnits, getUnitInfo } from './unitActions';

const initialState = {
    total_pages: 0,
    total_items: 0,
    items_per_page: 0,
    current_page: 0,
    current_page_items: 0,
    error: false,
    unitSuccess: false,
    unitsMsg: '',
    units: [],
    unit: undefined,
    unitsLoading: false
};
const Units = createSlice({
    name: 'Units',
    initialState,
    reducers: {
        clearUnitsMessages: (state) => {
            state.error = false;
            state.unitsMsg = '';
            state.unitSuccess = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUnits.pending, (state) => {
            state.unitsLoading = true;
        });
        builder.addCase(getUnits.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.units = action.payload?.data.items;
            state.error = action.payload?.error;
            state.unitsMsg = action.payload?.msg;
            state.unitSuccess = !action.payload?.error;
        });
        builder.addCase(getNotPaginatedUnits.pending, (state) => {
            state.unitsLoading = true;
        });
        builder.addCase(getNotPaginatedUnits.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.units = action.payload?.data;
            state.error = action.payload?.error;
            state.unitsMsg = action.payload?.msg;
            state.unitSuccess = !action.payload?.error;
        });

        builder.addCase(addUnits.pending, (state) => {
            state.unitsLoading = true;
        });
        builder.addCase(addUnits.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.error = action.payload?.error;
            state.unitSuccess = !action.payload?.error;
            state.unitsMsg = action.payload?.msg;
        });
        builder.addCase(updateUnits.pending, (state) => {
            state.unitsLoading = true;
        });
        builder.addCase(updateUnits.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.error = action.payload?.error;
            state.unitSuccess = !action.payload?.error;
            state.unitsMsg = action.payload?.msg;
        });
        builder.addCase(deleteUnits.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.total_pages = action.payload?.data.total_pages;
            state.total_items = action.payload?.data.total_items;
            state.items_per_page = action.payload?.data.items_per_page;
            state.current_page = action.payload?.data.current_page;
            state.current_page_items = action.payload?.data.current_page_items;
            state.units = action.payload?.data.items;
            state.error = action.payload?.error;
            state.unitsMsg = action.payload?.msg;
            state.unitSuccess = !action.payload?.error;
        });
        builder.addCase(getUnit.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.unit = action.payload?.data[0];
            state.error = action.payload?.error;
            state.unitsMsg = action.payload?.msg;
            state.unitSuccess = !action.payload?.error;
        });
        builder.addCase(getUnitInfo.fulfilled, (state, action) => {
            state.unitsLoading = false;
            state.unit = action.payload?.data[0];
            state.error = action.payload?.error;
            state.unitsMsg = action.payload?.msg;
            state.unitSuccess = !action.payload?.error;
        });
    }
});

export const { clearUnitsMessages } = Units.actions;

export default Units.reducer;
