/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

// login option 3 routing
const ListingDetails = Loadable(lazy(() => import('components/marketings/listingsDetails')));
const ViewListings = Loadable(lazy(() => import('components/marketings/view-listings')));
const InviteApplicationsForm = Loadable(lazy(() => import('views/pages/applications/inviteApplicationsForm')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const UtilsRoutes = {
    path: '',
    element: <MinimalLayout />,
    children: [
        {
            path: 'marketplace',
            element: <ViewListings />
        },
        {
            path: 'marketplace/:listing_id/details',
            element: <ListingDetails />
        },
        {
            path: 'marketplace/unit/:unit_id/apply',
            element: <InviteApplicationsForm />
        }
    ]
};

export default UtilsRoutes;
